import { useState, useEffect } from 'react';
import axios from 'axios';

export const useGamesData = () => {
    const [gamesData, setGamesData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchGamesData = async () => {
            const apiUrl = 'https://back-q4uu.onrender.com/games';
            try {
                const headers = {
                    'Content-Type': 'application/json'
                };
                const response = await axios({
                    method: 'GET',
                    headers,
                    url: apiUrl
                });

                if (response) {
                    const { data } = response;
                    setGamesData(data);
                } else {
                    setError('No data available');
                }
            } catch (error) {
                setError('Error fetching Games data: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchGamesData();
    }, []);

    return { gamesData, loading, error };
};
