import './Watch.scss'
export const Watch = () => {
    return (
        <div className="video-container">
            <iframe
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/TcRNPB01eMQ?si=PNYm-fNlUGq2AyEO`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>
    )
};
