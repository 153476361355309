import { useEffect, useState } from "react";
import './BannerCookies.scss'

export const BannerCookies = () => {
    const [visible, setVisible] = useState(true);

    const acceptCookies = () => {
        setVisible(false);
        document.getElementById('cookie-banner').style.display = 'none';
        localStorage.setItem('cookiesAccepted', 'true');
    };

    useEffect(() => {
        const cookiesAccepted = localStorage.getItem('cookiesAccepted');
        if (cookiesAccepted) {
            setVisible(false);
        }
    }, []);
    return (
        <>
            {visible && (<div id="cookie-banner" className='cookie-banner'>
                <p>Este sitio utiliza cookies de terceros para mejorar la experiencia del usuario. Estas cookies son necesarias
                    para el funcionamiento puesto que son proporcionadas por terceros como Steam y
                    YouTube para mostrar contenido y anuncios personalizados. Al continuar navegando, aceptas el uso de
                    cookies.</p>
                <h4>Uso de Cookies</h4>
                <p>Utilizamos cookies para proporcionar funciones de redes sociales. También compartimos información sobre el uso que haces de nuestro sitio con nuestros socios de redes sociales, publicidad y análisis, quienes pueden combinarla con otra información que les hayas proporcionado o que hayan recopilado a partir del uso de sus servicios.</p>
                <p>Algunas cookies son esenciales para el funcionamiento del sitio, mientras que otras son proporcionadas por terceros como Steam y YouTube para mostrar contenido y anuncios personalizados.</p>

                <button onClick={()=> acceptCookies()}>Aceptar</button>
            </div>)}
        </>
    )
}