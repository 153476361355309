import { Link } from 'react-router-dom';
import './Header.scss'

export const Header = () => {
    return (
        <header className="App-header">
            <div>
                <h5>Eñe3</h5>
            </div>
            <div className='nav-buttons'>
                <Link to="/" className='nav-button'>HOME</Link>
                <Link to="/watch" className='nav-button'>WATCH</Link>
            </div>
        </header>
    )
}