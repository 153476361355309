// VideoModal.js
import React from 'react';
import ReactModal from 'react-modal';
import ReactPlayer from 'react-player';
import './VideoModal.scss';

ReactModal.setAppElement('#root'); // Asegúrate de que el modal se monte en el elemento adecuado

export const VideoModal = ({ isOpen, onRequestClose, videoUrl }) => {
    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    height: '80%',
                },
            }}
        >
            <button className='close-modal' onClick={onRequestClose}>X</button>
            <ReactPlayer url={videoUrl} controls={true} width="100%" height="100%" />
        </ReactModal>
    );
};

