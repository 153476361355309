import './App.css';
import { Route, Routes } from 'react-router-dom';

import { Main } from "./Views/Main/Main";
import { Watch } from "./Views/Watch/Watch";
import { Header } from "./Components/Header/Header";
import { BannerCookies } from './Components/BannerCookies/BannerCookies'
import {Footer} from "./Components/Footer/Footer";

function App() {
  return (
    <div className="App">
        <Header />
         <div className='body'>
            <div className="hero-image">
            </div>
             <Routes>
                 <Route exact path="/" element={<Main />} />
                 <Route path="/watch" element={<Watch />} />
             </Routes>
             <BannerCookies />
         </div>
        <Footer />
    </div>
  );
}

export default App;
