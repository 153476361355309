import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { faWandMagicSparkles, faRectangleList, faCartArrowDown, faGamepad } from '@fortawesome/free-solid-svg-icons';
import { faSteam } from '@fortawesome/free-brands-svg-icons';
import { VideoModal } from "../VideoModal/VideoModal";
import './GameCard.scss';
import enie3 from '../../assets/img/background2.webp';

export const GameCard = ({ gameData, id }) => {
    const [aVisibleSide, setAVisibleSide] = useState(true);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const { title, developer, genre, images, links, platforms, publisher, releaseDate } = gameData;

    const changeSide = (side) => {
        setAVisibleSide(side);
    };

    const openModalWithVideo = (url) => {
        setVideoUrl(url);
        setModalIsOpen(true);
    };

    return (
        <>
            {aVisibleSide && (
                <div className='game-card-side-a'>
                    <h4 className='game-card-side-a__title'>{title}</h4>
                    <FontAwesomeIcon
                        className='game-card-side-a__image-container__icon'
                        icon={faRectangleList}
                        onClick={() => changeSide(false)}
                    />
                    <div className='game-card-side-a__image-container'>
                        <div className='game-card-side-a__image-container__image' onClick={() => openModalWithVideo(links.trailer)}>
                            <img src={images.thumbnail ? images.thumbnail : enie3} alt={title} />
                        </div>
                        <ul className='game-card-side-a__image-container__genre-list'>
                            {genre.length && genre.map((g, index) => (
                                <li key={index} className='genre-list__item'>
                                    {g}
                                </li>
                            ))}
                        </ul>
                        {links.steam && (
                            <a href={links.steam} aria-label='web where you can buy the game, opened in a new tab' target='_blank' rel='noopener noreferrer'>
                                <FontAwesomeIcon
                                    className='game-card-side-a__image-container__steam-icon'
                                    icon={faSteam}
                                />
                            </a>
                        )}
                        {links.shop && (
                            <a href={links.shop} aria-label='web where you can buy the game, opened in a new tab' target='_blank' rel='noopener noreferrer'>
                                <FontAwesomeIcon
                                    className='game-card-side-a__image-container__shop-icon'
                                    icon={faCartArrowDown}
                                />
                            </a>
                        )}
                    </div>
                </div>
            )}
            {!aVisibleSide && (
                <div className='game-card-side-b'>
                    <FontAwesomeIcon
                        className='game-card-side-a__image-container__icon'
                        icon={faWandMagicSparkles}
                        onClick={() => changeSide(true)}
                    />
                    <div className='data-info-container'>
                        <hgroup className='title-container'>
                            <h4 className='game-card-side-b__title'>{title}</h4>
                        </hgroup>
                        <hgroup className='info-container'>
                            <h5 className='field-name'>Desarrollador:</h5>
                            <h6 className='field-info'>{developer}</h6>
                        </hgroup>
                        <hgroup className='info-container'>
                            <h5 className='field-name'>Plataformas:</h5>
                            {platforms.length && (
                                <ul className='platform-list'>
                                    {platforms.map((platform, index) => <li key={ platform + index}>{platform}</li>)}
                                </ul>
                            )}
                        </hgroup>
                        <hgroup className='info-container'>
                            <h5 className='field-name'>Publisher:</h5>
                            <h6 className='field-info'>{publisher}</h6>
                        </hgroup>
                        <hgroup className='info-container'>
                            <h5 className='field-name'>Lanzamiento:</h5>
                            <h6 className='field-info'>{releaseDate ? (new Date(releaseDate).toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' })) : 'Pro determinar'}</h6>
                        </hgroup>
                    </div>
                    <div className='demo-container'>
                        {links.gameDemo &&  (<div>
                            <h3 key={links.gamedemo}>Demo</h3>
                            <a className='demo-link' href={links.gameDemo} aria-label='access to de Demo, opened in a new tab' target='_blank' rel='noopener noreferrer'><FontAwesomeIcon icon={faGamepad} /></a>
                        </div>)}

                    </div>
                </div>
            )}
            <VideoModal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                videoUrl={videoUrl}
            />
        </>
    );
};
