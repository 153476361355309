import { useGamesData } from "../../services/useGamesData";
import './Main.scss'
import { GameCard } from '../../Components/GameCard/GameCard';


export const Main = () => {
    const { gamesData, loading, error } = useGamesData();

    if (loading) {
        return <div className="loader"></div>;
    }

    if (error) {
        return <div>{error}</div>;
    }
    console.log('Hornilitos Love <3');

    return (
        <main className='main-home' role='main' key='mainView' >
            {loading && (<div className="loader"></div>)}
            {error && (<div>{error}</div>)}
            {gamesData && gamesData.map((game) => (<GameCard gameData={game} id={game.id} key={game.id} />))}
        </main>
    )
}