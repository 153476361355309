import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitch, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faHeartCircleBolt } from '@fortawesome/free-solid-svg-icons';
import './Footer.scss';

export const Footer = () => {
    return (
        <footer className="footer">
            <div className="icon-container">
                <div>
                    <span className='me'>Web Developed by Dyvelma  ^w^ </span>
                    <FontAwesomeIcon icon={faHeartCircleBolt} className='heart-icon' />
                    <span className='yuste'>and Created by Yuste</span>
                </div>

                <a href="https://www.twitch.tv/el_yuste" target="_blank" rel="noopener noreferrer" className="icon">
                    <FontAwesomeIcon icon={faTwitch} />
                    <span className="tooltip">Twitch</span>
                </a>
                <a href="https://x.com/el_yuste_" target="_blank" rel="noopener noreferrer" className="icon">
                    <FontAwesomeIcon icon={faTwitter} />
                    <span className="tooltip">Twitter</span>
                </a>
                <a href="https://www.instagram.com/el_yuste_ins/" target="_blank" rel="noopener noreferrer" className="icon">
                    <FontAwesomeIcon icon={faInstagram} />
                    <span className="tooltip">Instagram</span>
                </a>
                <a href="https://www.youtube.com/c/YusteYT" target="_blank" rel="noopener noreferrer" className="icon">
                    <FontAwesomeIcon icon={faYoutube} />
                    <span className="tooltip">Youtube Sports</span>
                </a>
                <a href="https://www.youtube.com/channel/UCSdRVh3vdGPii6L7MGEsZ-A" target="_blank" rel="noopener noreferrer" className="icon">
                    <FontAwesomeIcon icon={faYoutube} />
                    <span className="tooltip">Youtube Entrevistas</span>
                </a>
            </div>
        </footer>
    );
};

